@font-face {
  font-family: 'Tillnoonicon';
  src:  url('tillnoon-icon.eot?rlwysg');
  src:  url('tillnoon-icon.eot?rlwysg#iefix') format('embedded-opentype'),
    url('tillnoon-icon.ttf?rlwysg') format('truetype'),
    url('tillnoon-icon.woff?rlwysg') format('woff'),
    url('tillnoon-icon.svg?rlwysg#Tillnoonicon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="tnicon-"], [class*=" tnicon-"] {
  /* use !important to prevent issues with browser extensions that change font */
  font-family: 'Tillnoonicon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.tnicon-Acoustic--Classical-Guitar:before {
  content: "\e900";
}
.tnicon-Acoustic-Bass-Guitar:before {
  content: "\e901";
}
.tnicon-Audio-Interfaces:before {
  content: "\e902";
}
.tnicon-Bass-Amps:before {
  content: "\e903";
}
.tnicon-Cymbals:before {
  content: "\e904";
}
.tnicon-DJ-Controllers--Mixers:before {
  content: "\e905";
}
.tnicon-Drums--Percussion:before {
  content: "\e906";
}
.tnicon-Effect-Pedals:before {
  content: "\e907";
}
.tnicon-Electric-Bass-Guitar:before {
  content: "\e908";
}
.tnicon-Electric-Guitar:before {
  content: "\e909";
}
.tnicon-Guitar-Amps:before {
  content: "\e90a";
}
.tnicon-Keyboards:before {
  content: "\e90b";
}
.tnicon-Microphone:before {
  content: "\e90c";
}
.tnicon-Mixboards:before {
  content: "\e90d";
}
.tnicon-Monitors:before {
  content: "\e90e";
}
.tnicon-Pianos:before {
  content: "\e90f";
}
.tnicon-Preamps:before {
  content: "\e910";
}
.tnicon-Software--Plugins:before {
  content: "\e911";
}
.tnicon-Other:before {
  content: "\e912";
}
