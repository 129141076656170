@font-face {
  font-family: "Flaticon";
  src: url("./Flaticon.eot");
  src: url("./Flaticon.eot?#iefix") format("embedded-opentype"),
       url("Flaticon.woff2") format("woff2"),
       url("Flaticon.woff") format("woff"),
       url("Flaticon.ttf") format("truetype"),
       url("./Flaticon.svg#Flaticon") format("svg");
  font-weight: normal;
  font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: "Flaticon";
    src: url("./Flaticon.svg#Flaticon") format("svg");
  }
}

.fimanager:before {
    display: inline-block;
  font-family: "Flaticon";
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  line-height: 1;
  text-decoration: inherit;
  text-rendering: optimizeLegibility;
  text-transform: none;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
}

.flaticon-001-accordion:before { content: "\f100"; }
.flaticon-002-banjo:before { content: "\f101"; }
.flaticon-003-bass:before { content: "\f102"; }
.flaticon-004-loudspaker:before { content: "\f103"; }
.flaticon-005-cello:before { content: "\f104"; }
.flaticon-006-clarinet:before { content: "\f105"; }
.flaticon-007-cymbals:before { content: "\f106"; }
.flaticon-008-cymbals:before { content: "\f107"; }
.flaticon-009-cymbal:before { content: "\f108"; }
.flaticon-010-club:before { content: "\f109"; }
.flaticon-011-disc-jockey:before { content: "\f10a"; }
.flaticon-012-party:before { content: "\f10b"; }
.flaticon-013-drum:before { content: "\f10c"; }
.flaticon-014-drum-set:before { content: "\f10d"; }
.flaticon-015-music-instrument:before { content: "\f10e"; }
.flaticon-016-kit:before { content: "\f10f"; }
.flaticon-017-drum-kit:before { content: "\f110"; }
.flaticon-018-electric-guitar:before { content: "\f111"; }
.flaticon-019-flutes:before { content: "\f112"; }
.flaticon-020-flute:before { content: "\f113"; }
.flaticon-021-music:before { content: "\f114"; }
.flaticon-022-wind-instrument:before { content: "\f115"; }
.flaticon-023-music:before { content: "\f116"; }
.flaticon-024-music:before { content: "\f117"; }
.flaticon-025-gong:before { content: "\f118"; }
.flaticon-026-guitar:before { content: "\f119"; }
.flaticon-027-effect:before { content: "\f11a"; }
.flaticon-028-instrument:before { content: "\f11b"; }
.flaticon-029-wind-instrument:before { content: "\f11c"; }
.flaticon-030-folk:before { content: "\f11d"; }
.flaticon-031-harp:before { content: "\f11e"; }
.flaticon-032-kalimba:before { content: "\f11f"; }
.flaticon-033-kendang:before { content: "\f120"; }
.flaticon-034-african:before { content: "\f121"; }
.flaticon-035-kettledrum:before { content: "\f122"; }
.flaticon-036-music:before { content: "\f123"; }
.flaticon-037-keyboard:before { content: "\f124"; }
.flaticon-038-entertainment:before { content: "\f125"; }
.flaticon-039-music-instrument:before { content: "\f126"; }
.flaticon-040-keytar:before { content: "\f127"; }
.flaticon-041-launchpad:before { content: "\f128"; }
.flaticon-042-wind-instrument:before { content: "\f129"; }
.flaticon-043-microphone:before { content: "\f12a"; }
.flaticon-044-controller:before { content: "\f12b"; }
.flaticon-045-fighter:before { content: "\f12c"; }
.flaticon-046-tall-drum:before { content: "\f12d"; }
.flaticon-047-sampler:before { content: "\f12e"; }
.flaticon-048-saxophone:before { content: "\f12f"; }
.flaticon-049-siku:before { content: "\f130"; }
.flaticon-050-tambourine:before { content: "\f131"; }
.flaticon-051-alert:before { content: "\f132"; }
.flaticon-052-bell:before { content: "\f133"; }
.flaticon-053-trumpets:before { content: "\f134"; }
.flaticon-054-wind:before { content: "\f135"; }
.flaticon-055-ukulele:before { content: "\f136"; }
.flaticon-056-violin:before { content: "\f137"; }
.flaticon-057-vocalist:before { content: "\f138"; }
.flaticon-058-xylophone:before { content: "\f139"; }

$font-Flaticon-001-accordion: "\f100";
$font-Flaticon-002-banjo: "\f101";
$font-Flaticon-003-bass: "\f102";
$font-Flaticon-004-loudspaker: "\f103";
$font-Flaticon-005-cello: "\f104";
$font-Flaticon-006-clarinet: "\f105";
$font-Flaticon-007-cymbals: "\f106";
$font-Flaticon-008-cymbals: "\f107";
$font-Flaticon-009-cymbal: "\f108";
$font-Flaticon-010-club: "\f109";
$font-Flaticon-011-disc-jockey: "\f10a";
$font-Flaticon-012-party: "\f10b";
$font-Flaticon-013-drum: "\f10c";
$font-Flaticon-014-drum-set: "\f10d";
$font-Flaticon-015-music-instrument: "\f10e";
$font-Flaticon-016-kit: "\f10f";
$font-Flaticon-017-drum-kit: "\f110";
$font-Flaticon-018-electric-guitar: "\f111";
$font-Flaticon-019-flutes: "\f112";
$font-Flaticon-020-flute: "\f113";
$font-Flaticon-021-music: "\f114";
$font-Flaticon-022-wind-instrument: "\f115";
$font-Flaticon-023-music: "\f116";
$font-Flaticon-024-music: "\f117";
$font-Flaticon-025-gong: "\f118";
$font-Flaticon-026-guitar: "\f119";
$font-Flaticon-027-effect: "\f11a";
$font-Flaticon-028-instrument: "\f11b";
$font-Flaticon-029-wind-instrument: "\f11c";
$font-Flaticon-030-folk: "\f11d";
$font-Flaticon-031-harp: "\f11e";
$font-Flaticon-032-kalimba: "\f11f";
$font-Flaticon-033-kendang: "\f120";
$font-Flaticon-034-african: "\f121";
$font-Flaticon-035-kettledrum: "\f122";
$font-Flaticon-036-music: "\f123";
$font-Flaticon-037-keyboard: "\f124";
$font-Flaticon-038-entertainment: "\f125";
$font-Flaticon-039-music-instrument: "\f126";
$font-Flaticon-040-keytar: "\f127";
$font-Flaticon-041-launchpad: "\f128";
$font-Flaticon-042-wind-instrument: "\f129";
$font-Flaticon-043-microphone: "\f12a";
$font-Flaticon-044-controller: "\f12b";
$font-Flaticon-045-fighter: "\f12c";
$font-Flaticon-046-tall-drum: "\f12d";
$font-Flaticon-047-sampler: "\f12e";
$font-Flaticon-048-saxophone: "\f12f";
$font-Flaticon-049-siku: "\f130";
$font-Flaticon-050-tambourine: "\f131";
$font-Flaticon-051-alert: "\f132";
$font-Flaticon-052-bell: "\f133";
$font-Flaticon-053-trumpets: "\f134";
$font-Flaticon-054-wind: "\f135";
$font-Flaticon-055-ukulele: "\f136";
$font-Flaticon-056-violin: "\f137";
$font-Flaticon-057-vocalist: "\f138";
$font-Flaticon-058-xylophone: "\f139";
