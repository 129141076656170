$bbGreen: #00B6B2;
$bbBlack: #00000C;
$bbRed: #BA002D;
$bbGrey: #1B1B1E;
$bbWhite: #FFFFFF;
$bbWarning: #FFC200;
$bbSecondaryGrey: #asdasd;
$bbSecondary: #414042;



$primaryColor: #3CD1C5;
$primaryLightColor: #B1EDE8 !default;
$primaryDarkColor: #18544F !default;
$primaryDarkerColor: #123F3B !default;
$primaryTextColor: #ffffff;

$secondaryColor: #6c4796;
$secondaryLightColor: #C4B5D5 !default;
$secondaryDarkColor: #412B5A !default;
$secondaryDarkerColor: #20152D !default;
$secondaryTextColor: #ffffff;


$successButtonBg: $primaryColor;
$warningButtonBg: #F8941E;
$helpButtonBg: #63DAD1;
$dangerButtonBg: #B5142C;

//$successButtonBg: #61b23b;
//$warningButtonBg: #F8941E;
//$helpButtonBg: #63DAD1;
//$dangerButtonBg: #B5142C;


$shade000:#252525;    //surface
$shade100:#1C1B1F;    //header background
$shade200:#1C1B1F;    //hover background
$shade300:#dfe7ef;    //border, divider
$shade400:#d3dbe3;    //input border
$shade500:#c6ccd7;    //input icon
$shade600:#708da9;    //text secondary color
$shade700:#E6E1E5;    //text color
$shade800:#E6E1E5;    //unused
$shade900:#212529;    //unused

$additional1: #61b23b;
$additional2: #29303C;
