/* You can add global styles to this file, and also import other style files */
$gutter: 1rem; //for primeflex grid system

@import "../node_modules/primeng/resources/primeng.min.css";
@import "../node_modules/primeflex/primeflex.scss";
@import "../node_modules/primeicons/primeicons.css";
@import "../node_modules/prismjs/themes/prism-coy.css";
//@import "../node_modules/@fullcalendar/daygrid/main.min.css";
//@import "../node_modules/@fullcalendar/timegrid/main.min.css";
@import "assets/demo/flags/flags.css";
@import "assets/theme/bandbeat/colors";
@import "assets/theme/bandbeat/flaticon/font/flaticon";
@import "assets/theme/bandbeat/tillnoon-icon/font/tillnoon-icon";

.material-icons-outlined{
  vertical-align: middle;
}
.material-icons-outlined:after {
  content: attr(data-icon);
}

.material-symbols-outlined{
  vertical-align: middle;
}
.material-symbols-outlined:after {
  content: attr(data-icon);
}

.flaticon{
  font-family: Flaticon;
  font-weight: bold;
  font-style: normal;
  font-size: 24px;
  padding:4px;
  display: inline-block;
}

.till-noon-icon{
  font-family: Tillnoonicon;
  font-weight: bold;
  font-style: normal;
  font-size: 24px;
  padding:4px;
  display: inline-block;
}

.keyword{
  color: $primaryColor;
}

.p-dialog-content {
  overflow: visible !important;
}

.bandbeatPage {
  padding : 2rem;
  margin-right: unset;
}

.layout-content {
  padding: unset !important;
}

.landing-body {
  background: linear-gradient(147.38deg, #050505 99%, #121212 77%) !important;
}

.layout-topbar .layout-topbar-left {
  .layout-topbar-main-menu li a {
    color: #FFFFFF;
  }
}

.coverImage {
  position : relative;
}

.socialList {
  list-style-type: none;
  overflow: hidden;
  padding: 0px;
  margin: 0px;
  li {
    float: left;
    margin: 2px;
    button {
      height : 24px;
      width: 24px;
    }
  }
}

.mask-icon {
  .p-input-icon-right > i:last-of-type  {
    color: #FFFFFF !important;
  }
}

@media screen and (max-width: 576px) {
  .bandbeatPage {
    margin-right: 0px;
    margin-left: 0px;
    padding:1rem;
  }
  .layout-wrapper .layout-content-wrapper {
    margin-top: 4.4rem !important;
  }

  .layout-wrapper .layout-sidebar {
    top: 4.4rem !important;
    height: calc(100% - 9rem) !important;
  }

  .optionBar {
    padding: 1rem;
  }
}


@media screen and (max-width: 991px) {
  .layout-wrapper {
    .layout-topbar {
      .layout-topbar-left {
        .menu-button {
          right:unset !important;
          left: 0 !important;
          border-radius: 0px !important;
        }
      }
    }
  }

  .close-icon {
    display: none;
  }
}


.bb-logo-loading {
  font-size: 40px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -28px;
  margin-top: -28px;
}

.l {
  height: 3em;
  width: 1em;
  border-radius: 1em 0 0 0;
  margin-bottom: -2em;
  background-color: $bbGreen;
}
.o {
  width: 4em;
  aspect-ratio: 1;
  border-radius: 100%;
  display: flex;
  background-color: $bbGreen;
}
.waves {
  margin: -3em 0 0 1em;
  width: 2em;
  aspect-ratio: 1;
  border-radius: 100%;
  display: flex;
  background-color: $bbBlack;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.wave {
  width: 15%;
  border-radius: 0.2em;
  margin: 0;
  background-color: white;
}
.wave-short {
  height: 0.50em;
  animation: play-short 1s infinite alternate;
}
.wave-long {
  height: 1.20em;
  animation: play-reverse .5s infinite linear;
}

@keyframes play-short {
  0% {
    height: .7em;
  }
  50% {
    height: 0.5em;
  }
  100% {
    height: .7em;
  }
}
@keyframes play-reverse {
  0% {
    height: 0.6em;
  }
  50% {
    height: 1.2em;
  }
  100% {
    height: 0.6em;
  }
}


@media screen and (max-width: 576px) {
  .bb-logo-loading {
    margin-left: -15%;
    margin-top: -15%;
  }
}
